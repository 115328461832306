.swagger-ui {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui .wrapper {
  padding: 0 0px;
}

.swagger-ui .parameter__type {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui .tab li {
  color: #0097bb;
}

.swagger-ui .opblock-description-wrapper,
.swagger-ui .opblock-external-docs-wrapper,
.swagger-ui .opblock-title_normal {
  color: rgba(255, 255, 255, 0.97);
}
.swagger-ui .opblock-description-wrapper h4,
.swagger-ui .opblock-external-docs-wrapper h4,
.swagger-ui .opblock-title_normal h4 {
  color: rgba(255, 255, 255, 0.97);
}
.swagger-ui .opblock-description-wrapper p,
.swagger-ui .opblock-external-docs-wrapper p,
.swagger-ui .opblock-title_normal p {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui section.models h4 {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui .opblock .opblock-section-header h4 {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui .opblock .opblock-section-header {
  background: #2a3037;
}

.swagger-ui label {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui .opblock .opblock-section-header > label {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui .opblock .opblock-summary-operation-id,
.swagger-ui .opblock .opblock-summary-path,
.swagger-ui .opblock .opblock-summary-path__deprecated {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui .opblock .opblock-summary-description {
  color: rgba(255, 255, 255, 0.97);
}
.swagger-ui .response-col_status {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui .response-col_links {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui .opblock-body pre.microlight {
  background: #14191f !important;
}

.swagger-ui .model-box-control:focus,
.swagger-ui .models-control:focus,
.swagger-ui .opblock-summary-control:focus {
  outline: none;
}

.swagger-ui .model {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui section.models h5 {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui .model-title {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui table thead tr td,
.swagger-ui table thead tr th {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui .parameter__name {
  color: rgba(255, 255, 255, 0.97);
}

.swagger-ui .parameter__extension,
.swagger-ui .parameter__in {
  color: rgba(255, 255, 255, 0.8);
}

.information-container {
  display: none;
}

.swagger-ui .opblock-tag.no-desc {
  display: none;
}

.swagger-ui .opblock.opblock-get {
  background: #14191f;
  border-color: rgba(255, 255, 255, 0.1);
}
.swagger-ui .opblock.opblock-get .opblock-summary-method {
  background: #3173c9;
}
.swagger-ui .opblock.opblock-get .opblock-summary {
  border-color: #3173c9;
}
.swagger-ui .opblock.opblock-get .tab-header .tab-item.active h4 span:after {
  background: #3173c9;
}
.swagger-ui .opblock.opblock-post {
  background: #14191f;
  border-color: rgba(255, 255, 255, 0.1);
}
.swagger-ui .opblock.opblock-post .opblock-summary-method {
  background: #1c8450;
}
.swagger-ui .opblock.opblock-post .opblock-summary {
  border-color: #1c8450;
}
.swagger-ui .opblock.opblock-post .tab-header .tab-item.active h4 span:after {
  background: #1c8450;
}
.swagger-ui .opblock.opblock-put {
  background: #14191f;
  border-color: rgba(255, 255, 255, 0.1);
}
.swagger-ui .opblock.opblock-put .opblock-summary-method {
  background: #fdb840;
}
.swagger-ui .opblock.opblock-put .opblock-summary {
  border-color: #fdb840;
}
.swagger-ui .opblock.opblock-put .tab-header .tab-item.active h4 span:after {
  background: #fdb840;
}

.swagger-ui .opblock.opblock-delete {
  background: #14191f;
  border-color: rgba(255, 255, 255, 0.1);
}
.swagger-ui .opblock.opblock-delete .opblock-summary-method {
  background: #d93638;
}
.swagger-ui .opblock.opblock-delete .opblock-summary {
  border-color: #d93638;
}
.swagger-ui .opblock.opblock-delete .tab-header .tab-item.active h4 span:after {
  background: #d93638;
}

.swagger-ui .opblock.opblock-patch {
  background: #14191f;
  border-color: rgba(255, 255, 255, 0.1);
}
.swagger-ui .opblock.opblock-patch .opblock-summary-method {
  background: #fdb840;
}
.swagger-ui .opblock.opblock-patch .opblock-summary {
  border-color: #fdb840;
}
.swagger-ui .opblock.opblock-patch .tab-header .tab-item.active h4 span:after {
  background: #fdb840;
}

.swagger-ui .opblock.opblock-head {
  background: #14191f;
  border-color: rgba(255, 255, 255, 0.1);
}
.swagger-ui .opblock.opblock-head .opblock-summary-method {
  background: #fdb840;
}
.swagger-ui .opblock.opblock-head .opblock-summary {
  border-color: #fdb840;
}
.swagger-ui .opblock.opblock-head .tab-header .tab-item.active h4 span:after {
  background: #fdb840;
}

.swagger-ui .opblock.opblock-options {
  background: #14191f;
  border-color: rgba(255, 255, 255, 0.1);
}
.swagger-ui .opblock.opblock-options .opblock-summary-method {
  background: #fdb840;
}
.swagger-ui .opblock.opblock-options .opblock-summary {
  border-color: #fdb840;
}
.swagger-ui
  .opblock.opblock-options
  .tab-header
  .tab-item.active
  h4
  span:after {
  background: #fdb840;
}

.swagger-ui .opblock.opblock-deprecated {
  background: #14191f;
  border-color: rgba(255, 255, 255, 0.1);
  opacity: 0.6;
}
.swagger-ui .opblock.opblock-deprecated .opblock-summary-method {
  background: #757575;
}
.swagger-ui .opblock.opblock-deprecated .opblock-summary {
  background: #757575;
}
.swagger-ui
  .opblock.opblock-deprecated
  .tab-header
  .tab-item.active
  h4
  span:after {
  background: #757575;
}

.arrow {
  fill: #ffffff;
  margin-right: 8px;
}

.swagger-ui .copy-to-clipboard {
  display: none;
}

.swagger-ui .try-out {
  display: none;
}

.swagger-ui .scheme-container {
  display: none;
}

.swagger-ui .authorization__btn {
  display: none;
}

.swagger-ui .models {
  display: none;
}
